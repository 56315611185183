import { useState } from "react";
import therapist1 from '../assets/img/therapist1.PNG'
import therapist2 from '../assets/img/therapist2.PNG'
const Theraipst = () => {
  const [selectedArticle, setSelectedArticle] = useState("article-02");
  const handleChange = (event) => {
    setSelectedArticle(event.target.id);
  };
  return (
    <section className="px-12">
      <div className="max-w-lg mx-auto relative">
        <input
          id="article-01"
          type="radio"
          name="slider"
          className="sr-only peer/01"
          checked={selectedArticle === "article-01"}
          onChange={handleChange}
        />
        <input
          id="article-02"
          type="radio"
          name="slider"
          className="sr-only peer/02"
          checked={selectedArticle === "article-02"}
          onChange={handleChange}
        />


        <div
          className="
            absolute inset-0 scale-[67.5%] z-20 transition-all duration-500 ease-[cubic-bezier(0.25,1,0.5,1)]
            peer-focus-visible/01:[&_article]:ring
            peer-focus-visible/01:[&_article]:ring-indigo-300
            peer-checked/01:relative
            peer-checked/01:z-50
            peer-checked/01:translate-x-0
            peer-checked/01:scale-100
            peer-checked/01:[&>label]:pointer-events-none
            peer-checked/02:-translate-x-20
            peer-checked/02:scale-[83.75%]
            peer-checked/02:z-40
            peer-checked/03:-translate-x-40
            peer-checked/03:z-30
            peer-checked/04:-translate-x-40                    
            peer-checked/04:opacity-0
            peer-checked/05:-translate-x-40
        "
        >
          <label className="absolute inset-0 cursor-pointer" htmlFor="article-01">
            <span className="sr-only">Focus on the big picture</span>
          </label>


          {/* <article className="bg-white border rounded-lg shadow-2xl">
            <img
              src={therapist2}
              width={'250'}
              height={'250'}
              className="w-full min-w-[250px] object-cover rounded-lg"
              alt="therapist"
            />
            <div className="absolute bottom-3 left-3 text-white text-left">
              <div className="font-bold text-lg mb-2">كندا فؤاد بركات</div>
              <div className="text-base">علاج نفسي</div>
              <div className="text-base">العلاج المعرفي السلوكي</div>
            </div>
          </article> */}
        </div>

        <div
          className="
            absolute inset-0 scale-[67.5%] z-20 transition-all duration-500 ease-[cubic-bezier(0.25,1,0.5,1)]
            peer-focus-visible/02:[&_article]:ring
            peer-focus-visible/02:[&_article]:ring-indigo-300                        
            peer-checked/01:translate-x-20
            peer-checked/01:scale-[83.75%]
            peer-checked/01:z-40
            peer-checked/02:relative
            peer-checked/02:z-50
            peer-checked/02:translate-x-0
            peer-checked/02:scale-100
            peer-checked/02:[&>label]:pointer-events-none
            peer-checked/03:-translate-x-20
            peer-checked/03:scale-[83.75%]
            peer-checked/03:z-40
            peer-checked/04:-translate-x-40
            peer-checked/04:z-30
            peer-checked/05:-translate-x-40 
            peer-checked/05:opacity-0
        "
        >
          <label className="absolute inset-0 cursor-pointer" htmlFor="article-02">
            <span className="sr-only">Focus on the big picture</span>
          </label>
          <article className="bg-white border rounded-lg shadow-2xl">
            <img
              src={therapist1}
              width={'250'}
              height={'250'}
              className="w-full min-w-[250px] object-cover rounded-lg"
              alt="therapist"
            />
            <div className="absolute bottom-3 left-3 text-white text-left">
              <div className="font-bold  text-lg mb-2 ">Victoria anelo eguilez</div>
              <div className="text-base">Online Therapy</div>
              <div className="text-base">CBT, Act, Humanistic...</div>
            </div>
          </article>
        </div>

        {/* <div
          className="
            absolute inset-0 scale-[67.5%] z-20 transition-all duration-500 ease-[cubic-bezier(0.25,1,0.5,1)]
            peer-focus-visible/03:[&_article]:ring
            peer-focus-visible/03:[&_article]:ring-indigo-300                          
            peer-checked/01:translate-x-40
            peer-checked/01:z-30
            peer-checked/02:translate-x-20
            peer-checked/02:scale-[83.75%]
            peer-checked/02:z-40
            peer-checked/03:relative
            peer-checked/03:z-50
            peer-checked/03:translate-x-0
            peer-checked/03:scale-100
            peer-checked/03:[&>label]:pointer-events-none
            peer-checked/04:-translate-x-20
            peer-checked/04:scale-[83.75%]
            peer-checked/04:z-40
            peer-checked/05:-translate-x-40
            peer-checked/05:z-30                  
        "
        >
          <label className="absolute inset-0" htmlFor="article-03">
            <span className="sr-only">Focus on the big picture</span>
          </label>
          <article className="bg-white p-6 rounded-lg shadow-2xl">
            <header className="mb-2">
              <img
                className="inline-flex rounded-full shadow mb-3"
                src="#"
                width="44"
                height="44"
                alt="Icon"
              />
              <h1 className="text-xl font-bold text-slate-900">
                Focus on the big picture
              </h1>
            </header>
            <div className="text-sm leading-relaxed text-slate-500 space-y-4 mb-2">
              <p>
                Many desktop publishing packages and web page editors now use
                Pinky as their default model text, and a search for more
                variants will uncover many web sites still in their infancy.
              </p>
              <p>
                All the generators tend to repeat predefined chunks as
                necessary, making this the first true generator on the Internet.
              </p>
            </div>
            <footer className="text-right">
              <a
                className="text-sm font-medium text-indigo-500 hover:underline"
                href="#0"
              >
                Read more --
              </a>
            </footer>
          </article>
        </div>

        <div
          className="
            absolute inset-0 scale-[67.5%] z-20 transition-all duration-500 ease-[cubic-bezier(0.25,1,0.5,1)]
            peer-focus-visible/04:[&_article]:ring
            peer-focus-visible/04:[&_article]:ring-indigo-300                          

            peer-checked/01:translate-x-40 
            peer-checked/01:opacity-0
            
            peer-checked/02:translate-x-40
            peer-checked/02:z-30
            
            peer-checked/03:translate-x-20
            peer-checked/03:scale-[83.75%]
            peer-checked/03:z-40

            peer-checked/04:relative
            peer-checked/04:z-50
            peer-checked/04:translate-x-0
            peer-checked/04:scale-100
            peer-checked/04:[&>label]:pointer-events-none
            
            peer-checked/05:-translate-x-20
            peer-checked/05:scale-[83.75%]
            peer-checked/05:z-40
        "
        >
          <label className="absolute inset-0" htmlFor="article-04">
            <span className="sr-only">Focus on the big picture</span>
          </label>
          <article className="bg-white p-6 rounded-lg shadow-2xl">
            <header className="mb-2">
              <img
                className="inline-flex rounded-full shadow mb-3"
                src="#"
                width="44"
                height="44"
                alt="Icon"
              />
              <h1 className="text-xl font-bold text-slate-900">
                Focus on the big picture
              </h1>
            </header>
            <div className="text-sm leading-relaxed text-slate-500 space-y-4 mb-2">
              <p>
                Many desktop publishing packages and web page editors now use
                Pinky as their default model text, and a search for more
                variants will uncover many web sites still in their infancy.
              </p>
              <p>
                All the generators tend to repeat predefined chunks as
                necessary, making this the first true generator on the Internet.
              </p>
            </div>
            <footer className="text-right">
              <a
                className="text-sm font-medium text-indigo-500 hover:underline"
                href="#0"
              >
                Read more --
              </a>
            </footer>
          </article>
        </div>

        <div
          className="
            absolute inset-0 scale-[67.5%] z-20 transition-all duration-500 ease-[cubic-bezier(0.25,1,0.5,1)]
            peer-focus-visible/05:[&_article]:ring
            peer-focus-visible/05:[&_article]:ring-indigo-300                          
            peer-checked/01:translate-x-40 
            peer-checked/02:translate-x-40 
            peer-checked/02:opacity-0
            peer-checked/03:translate-x-40
            peer-checked/03:z-30
            peer-checked/04:translate-x-20
            peer-checked/04:scale-[83.75%]
            peer-checked/04:z-40
            peer-checked/05:relative
            peer-checked/05:z-50
            peer-checked/05:translate-x-0
            peer-checked/05:scale-100
            peer-checked/05:[&>label]:pointer-events-none
        "
        >
          <label className="absolute inset-0" htmlFor="article-05">
            <span className="sr-only">Focus on the big picture</span>
          </label>
          <article className="bg-white p-6 rounded-lg shadow-2xl">
            <header className="mb-2">
              <img
                className="inline-flex rounded-full shadow mb-3"
                src="#"
                width="44"
                height="44"
                alt="Icon"
              />
              <h1 className="text-xl font-bold text-slate-900">
                Focus on the big picture
              </h1>
            </header>
            <div className="text-sm leading-relaxed text-slate-500 space-y-4 mb-2">
              <p>
                Many desktop publishing packages and web page editors now use
                Pinky as their default model text, and a search for more
                variants will uncover many web sites still in their infancy.
              </p>
              <p>
                All the generators tend to repeat predefined chunks as
                necessary, making this the first true generator on the Internet.
              </p>
            </div>
            <footer className="text-right">
              <a
                className="text-sm font-medium text-indigo-500 hover:underline"
                href="#0"
              >
                Read more --
              </a>
            </footer>
          </article>
        </div> */}
      </div>
    </section>
  );
};

export default Theraipst;
