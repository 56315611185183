import React from 'react';
import logo from './logo.svg';
import './App.css';
import HomePage from './home-page/Page';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import FloatingButtons from './components/FloatingButtons';

function App() {
  return (
   <>
   <Navbar/>
    <HomePage/>
    <FloatingButtons/>
    <Footer/>
   </>
     );
}

export default App;
