import heroImg from "../assets/img/hero1.png";
import heartLive from "../assets/img/heartl-ive.png";
import { Container } from "../components/Container";
import Button from "../components/Button";

export const Hero = () => {
  return (
    <section aria-labelledby="hero-title">
      <Container className="flex flex-wrap text-left sm:text-left">
        <div className="flex items-center w-full lg:w-1/2">
          <div className="mb-8 mx-auto">
            <span>
              <img
                src={heartLive}
                width="80"
                className={"object-contain mb-3 sm:mx-0"}
                alt="Heart live illustration"
                loading="lazy"
                placeholder="empty"
              />
            </span>

            <h1
              id="hero-title"
              className="title font-bold leading-snug tracking-tight text-gray-800 lg:leading-tight xl:leading-tight"
            >
              UAE'S Most Affordable
              <span className="text-blue-400 ml-1">Online Therapy</span> Service,
              staffed by Licensed Therapists, for only 90 AED per session.
            </h1>

            <p style={{fontSize: 12}} className="text text-gray-500">
              We believe that healthcare can be affordable while maintaining its
              clinical integrity. By using our service, you are supporting our
              vision for a more affordable healthcare world. Together, step by
              step, we can make it happen.
            </p>

            <div className="flex items-start justify-start sm:justify-start gap-3 mt-5 sm:mt-auto">
              <Button
                className="text-white border-blue-500 bg-blue-500"
                title="I'm Ready to Heal"
              />
            </div>
          </div>
        </div>

        <div className="max-h-[80vh] flex items-center justify-center w-full lg:w-1/2">
          <img
            src={heroImg}
            className={"object-contain w-[80%] lg:w-[60%]"}
            alt="IcareWellBeing doctor image"
            loading="lazy"
            placeholder="empty"
          />
        </div>
      </Container>
    </section>
  );
};
