import howItWorks from "../assets/img/how-it-works.png";
import heartLive from "../assets/img/heartl-ive.png";
import heartLive2 from "../assets/img/heart2.png";
import { Container } from "../components/Container";
import SectionTitle from "../components/SectionTitle";
import Button from "../components/Button";

export const HowItWorks = () => {
  return (
    <Container className={"text-center sm:text-left"}>
      <div className="flex w-full items-center justify-content flex-col">
        <span className="w-48 my-2 inline-block h-1 bg-blue-500 rounded-full"></span>
           <img
              src={heartLive}
              className={"mx-auto sm:mx-0 my-2 object-contain w-20"}
              alt="Hero Illustration"
              loading="lazy"
              placeholder="empty"
              />
      </div>
      <SectionTitle
        preTitle="Explore how we make your experience tailored uniquely to you"
        line={false}
        preTitleClass="text-xl"
        title={''}
        className="font-bold"
        childrenClass="text-center mt-4"
      >
       <strong>1.</strong> First, choose the service that best meets your needs. <br/>
       <strong>2.</strong> Next, select a preferred time. Please note that it is subject to change.<br/>
       <strong>3.</strong> Finally, fill out a form that helps us match you with a therapist based on your preferences.
     
      </SectionTitle>

      <Container className="flex flex-wrap">
        <div className="flex items-center lg:px-10 justify-center w-full lg:w-1/2">
          <div className="max-h-[80vh]">
            <img
              src={howItWorks}
              className={"mx-auto sm:mx-0 object-contain w-[80%] "}
              alt="Hero Illustration"
              loading="lazy"
              placeholder="empty"
            />
          </div>
        </div>
        <div className="flex items-center lg:px-10 w-full lg:w-1/2">
          <div className="max-w-2xl">
            <SectionTitle
              titleClass="sm:text-left"
              childrenClass="sm:text-left"
              title={''}
              line={false}
            >
              <span className="lg:text-xl">
                Complete the form based on your preferences and mental health needs. Legitimate information enhances your experience.
              </span>
            </SectionTitle>



            <div className="flex w-full justify-center flex-col items-center">
              <Button
                title={'Find My Therapist'}
                className=" w-1/2 text-white bg-blue-500 border-blue-500"
              />
            </div>
          </div>
        </div>
        <div className="mt-4 flex flex-row w-full justify-center items-center">
            <img
              src={heartLive2}
              className={"mx-auto sm:mx-0 my-3 object-contain w-[90px]"}
              alt="Hero Illustration"
              loading="lazy"
              placeholder="empty"
              />
          </div>
      </Container>
    </Container>
  );
};
