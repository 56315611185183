import { PiCertificateBold } from "react-icons/pi";
import SectionTitle from "../components/SectionTitle";
import Theraipst from "./Theraipst";
import licensed from '../assets/img/licensed.webp';
import Button from "../components/Button";
import { Container } from "../components/Container";

const LicensedPsychologist = () => {
  return (
     <Container>
        <SectionTitle
          title="iCareWellbeing's licensed psychologists provide care and treatment for various conditions"
          titleClass="mt-5"
        />
        
        <div className="flex flex-col justify-center items-center max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
          <div className="flex flex-col-reverse lg:flex-row gap-8">
            
            {/* Therapist Info Section */}
            <article className="flex-1 text-center">
              <div className="mx-auto max-w-lg text-center lg:mx-0 ltr:lg:text-left rtl:lg:text-right">
                <Theraipst />
              </div>
              <p className="mt-5">
                To learn more about our therapists, visit 
                <a href="https://icarewellbeing.com/home/icarewellbeing-therapist-profile/" className="text-blue-500 underline ml-1">
                   iCareWellbeing Therapists page.
                </a>
              </p>
            </article>

            {/* img Section */}
            <article className="flex justify-center">
              <img
                src={licensed}
                className="h-full w-full object-contain w-[80%]"
                alt="Licensed psychologists providing care and treatment"
              />
            </article>
          </div>

          {/* CTA Button */}
         <div className="flex w-full items-center justify-center">
           <Button 
            className="mt-4 text-white border-blue-500 bg-blue-500 sm:ml-20"
            title="Start Healing"
            aria-label="Start your healing journey"
          />
         </div>
        </div>
      </Container>
  );
};

export default LicensedPsychologist;
